/* eslint-disable @typescript-eslint/quotes */
import markerSDK from '@marker.io/browser';
import { StatusBar, Style } from '@capacitor/status-bar';

import { Component, NgZone, ViewChild } from '@angular/core';
import {
  NavController,
  MenuController,
  AlertController,
  IonNav,
  Platform,
  ToastController,
  LoadingController
} from '@ionic/angular';
import { ActivatedRoute, Event, Router, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TimeagoIntl } from 'ngx-timeago';
import { strings as frShortStrings } from 'ngx-timeago/language-strings/fr-short';

import { UserService } from './services/user.service';
import { User } from './models/user.interface';
import { Address } from './models/address.interface';
import { ChatService } from './services/chat.service';
import { NotificationService } from './services/notification.service';
import { TabService } from './services/tab.service';
import { GeolocationService } from './services/geolocation.service';
import { SpotSearchService } from './services/spot-search.service';
import { SideMenuService } from './services/side-menu.service';
import { SpotFilters } from './models/spot-filters.interface';

import { SideMenuPage } from './side-menu/side-menu.page';
import { WelcomePage } from './welcome/welcome.page';
import { LoginPage } from './login/login.page';
import { SetPasswordPage } from './set-password/set-password.page';
import { RegisterPage } from './register/register.page';
import { ForgotPasswordPage } from './forgot-password/forgot-password.page';
import { Subscription } from 'rxjs';
import { UserGamificationService } from './services/user-gamification.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { UserGamification } from './models/user-gamification.interface';
import { AppSetting } from './models/app-setting.interface';
import { AppSettingService } from './services/app-setting.service';
import { StripeServiceService } from './services/stripe-service.service';
import { CloudMessagingService } from './services/cloud-messaging.service';
import { SeoService } from './services/seo.service';

declare const window: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  @ViewChild('sideMenuNav') sideMenuNav: IonNav;

  currentUrl = '/';
  hideInterface = false;

  menuPage: any;

  displayMenu = true;

  currentUser: User;

  suggestions: google.maps.places.AutocompletePrediction[] = [];
  query: string;
  updatingSearch = false;

  currentSpotFilters: SpotFilters;
  urlRedirectingToLoginPage: string;

  listenUserGamification: Subscription;

  pushInitiatedForUserId: string;

  constructor(
    private navCtrl: NavController,
    private menuCtrl: MenuController,
    private router: Router,
    private zone: NgZone,
    private translate: TranslateService,
    private timeagoIntl: TimeagoIntl,
    private userService: UserService,
    private tabService: TabService,
    private geolocationService: GeolocationService,
    private alertController: AlertController,
    private spotSearchService: SpotSearchService,
    private sideMenuService: SideMenuService,
    private platform: Platform,
    private userGamificationService: UserGamificationService,
    private toastController: ToastController,
    private appSettingService: AppSettingService,
    private route: ActivatedRoute,
    private loadingController: LoadingController,
    private stripeService: StripeServiceService,
    private cloudMessagingService: CloudMessagingService,
    private seoService: SeoService
  ) {
    document.addEventListener('backbutton', () => {
      //console.log('retour ');
      this.navCtrl.back();
    });

    this.stripeService.init();

    this.translate.setDefaultLang('fr');

    this.timeagoIntl.strings = frShortStrings;
    this.timeagoIntl.changes.next();

    this.userService.currentUserObservable.subscribe((currentUser: User) => {
      this.currentUser = currentUser;

      this.listenToUserGamification();

      this.zone.run(async () => {
        if (this.currentUser) {
          this.initPushNotifications();

          if (this.currentUser.darkThemeEnabled !== null) {
            this.toggleDarkTheme(this.currentUser.darkThemeEnabled);
          } else {
            this.autoDarkTheme();
          }
        }
      });
      //
      this.route.queryParams.subscribe(async params => {
        if (params.code) {
          this.router.navigateByUrl('/stripe/code/' + params.code);
        }
      });
    });

    this.userService.darkThemeEnabledObservable.subscribe((darkThemeEnabled: boolean) => {
      this.zone.run(async () => {
        if (darkThemeEnabled !== null) {
          this.toggleDarkTheme(darkThemeEnabled);
        }
      });
    });

    this.spotSearchService.currentSpotFiltersObservable.subscribe(
      (currentSpotFilters: SpotFilters) => {
        this.zone.run(() => {
          this.currentSpotFilters = currentSpotFilters;
        });
      }
    );

    this.sideMenuService.pageToLoadInSideMenuObservable.subscribe(
      (pageToLoadInSideMenu: string) => {
        if (pageToLoadInSideMenu) {
          this.changeNavPage(pageToLoadInSideMenu);
        }
      }
    );

    this.initializeApp();
    this.initDeeplinks();
  }

  initDeeplinks() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        //console.log({ event });
        const slug = event.url.split('.app').pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }

  initializeApp() {
    if (this.platform.is('capacitor')) {
      StatusBar.setStyle({ style: Style.Dark });
    }

    this.cloudMessagingService.clearBadgeNumber();

    this.cloudMessagingService.initListeners();

    //this.loadMarkerIo();
    this.loadCookieconsentScript();
    this.loadGoogleAnalytics();

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('template') === 'web') {
      window.hideInterface = true;
      this.hideInterface = window.hideInterface;
    }

    this.updateMenuEnabled();

    this.router.events.subscribe((event: Event) => {
      this.resetSeo();

      if (event instanceof RoutesRecognized) {
        this.currentUrl = event.url;

        this.tabService.updateTabBarFromUrl(this.currentUrl);
        this.updateMenuEnabled();
      }
    });
  }

  resetSeo(): void {
    this.seoService.setDefaultSeo();
  }

  async changeNavPage(page: string): Promise<void> {
    this.menuCtrl.open();

    if (page === 'back') {
      if (this.sideMenuNav.canGoBack()) {
        await this.sideMenuNav.pop();
      }
    } else if (page === 'root') {
      await this.sideMenuNav.popToRoot();
    } else {
      switch (page) {
        case 'welcome':
          this.sideMenuNav.push(WelcomePage);
          break;
        case 'login':
          this.sideMenuNav.push(LoginPage);
          break;
        case 'set-password':
          this.sideMenuNav.push(SetPasswordPage);
          break;
        case 'register':
          this.sideMenuNav.push(RegisterPage);
          break;
        case 'forgot-password':
          this.sideMenuNav.push(ForgotPasswordPage);
          break;
      }
    }
  }

  updateMenuEnabled(): void {
    this.zone.run(() => {
      this.displayMenu = this.currentUrl.length > 0; // && this.currentUrl.match('/app') !== null;

      if (window.hideInterface) {
        this.displayMenu = false;
      }

      this.menuCtrl.enable(this.displayMenu);
      this.menuPage = SideMenuPage;
    });
  }

  closeMenu() {
    this.menuCtrl.close();
  }

  openUrl(url: string, root?: boolean): void {
    this.menuCtrl.close();

    if (root) {
      this.navCtrl.navigateRoot(url);
    } else {
      this.navCtrl.navigateForward(url);
    }
  }

  autoDarkTheme(): void {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    this.toggleDarkTheme(prefersDark.matches);
  }

  toggleDarkTheme(enabled: boolean): void {
    document.body.classList.toggle('dark', enabled);
  }

  async updateQuery(query: string): Promise<void> {
    this.suggestions = [];

    if (
      !this.updatingSearch &&
      (!this.currentSpotFilters.address ||
        query !== this.currentSpotFilters.address.formattedAddress)
    ) {
      if (query.length > 2) {
        try {
          this.suggestions = await this.geolocationService.autocompleteAdress(query, 'fr');
        } catch (err) {
          //console.log(err);
          this.presentErrorAlert(err);
        }
      }
    }
  }

  async selectSuggestion(suggestion: google.maps.places.AutocompletePrediction): Promise<void> {
    try {
      this.updatingSearch = true;

      const result: Address = await this.geolocationService.getPlaceDetails(suggestion.place_id);

      this.spotSearchService.updateCurrentAddress(result);

      this.menuCtrl.close();

      this.query = '';

      this.router.navigate(['/spot']);
    } catch (err) {
      //console.log(err);
      this.presentErrorAlert(err);
    } finally {
      this.updatingSearch = false;
    }

    this.suggestions = [];
  }

  async presentErrorAlert(message: string): Promise<void> {
    const alert = await this.alertController.create({
      message,
      buttons: ['OK']
    });

    await alert.present();
  }

  async loadMarkerIo(): Promise<void> {
    // if (
    //   !this.platform.is('capacitor') &&
    //   (window.location.href.match('spotncharge-dev') ||
    //     window.location.href.match('spotncharge.app'))
    //   // || window.location.href.match('localhost')
    // ) {
    //   const widget = await markerSDK.loadWidget({
    //     destination: '6201336974bacd49c47c82ef'
    //   });
    // }
  }

  listenToUserGamification(): void {
    if (this.listenUserGamification) {
      this.listenUserGamification.unsubscribe();
    }

    if (this.currentUser) {
      this.listenUserGamification = this.userGamificationService
        .listenUserGamification(this.currentUser.id)
        .subscribe((result: any) => {
          if (result.data.newUserGamification) {
            const userGamification: UserGamification = result.data
              .newUserGamification as UserGamification;

            if (userGamification.nbPoints > 0) {
              this.presentToast(
                'Félicitations 👍 Vous avez gagné ' + userGamification.nbPoints + ' point(s) 😊'
              );
            } else if (userGamification.nbPoints < 0) {
              this.presentToast(
                'Malheureuseument, vous avez perdu ' +
                  userGamification.nbPoints * -1 +
                  ' point(s) ☹️'
              );
            }
          }
        });
    }
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      icon: 'ribbon',
      color: 'secondary',
      buttons: [
        {
          side: 'end',
          icon: 'close',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }

  async loadAxeptioScript(): Promise<void> {
    if (!this.platform.is('capacitor')) {
      const appSettingCode = 'axeptio_client_id';

      const appSetting: AppSetting = await this.appSettingService.getFromCode(appSettingCode);

      if (appSetting?.value) {
        const script = document.createElement('script');

        script.innerHTML =
          'window.axeptioSettings = {' +
          'clientId: "' +
          appSetting.value +
          '",' +
          '};' +
          '(function(d, s) {' +
          'var t = d.getElementsByTagName(s)[0], e = d.createElement(s);' +
          'e.async = true; e.src = "//static.axept.io/sdk.js";' +
          't.parentNode.insertBefore(e, t);' +
          '})(document, "script");';

        document.body.appendChild(script);
      }
    }
  }

  async loadCookieconsentScript(): Promise<void> {
    if (!this.platform.is('capacitor')) {
        const popperScript = document.createElement('script');
        popperScript.src = 'https://cookieconsent.popupsmart.com/src/js/popper.js';
        popperScript.onload = () => {
            if (typeof window.start !== 'undefined' && window.start.init) {
                window.start.init({
                    Palette: "palette2",
                    Mode: "banner bottom",
                    Theme: "block",
                    Message: "Ce site utilise des cookies afin d'offrir la meilleure expérience possible lors de ta visite.",
                    ButtonText: "OK !",
                    LinkText: "Confidentialité",
                    Location: "https://spotncharge.com/confidentialite/",
                    Time: "5"
                });
            } else {
                console.error('Cookie consent init function not available.');
            }
        };
        popperScript.onerror = () => {
            console.error('Failed to load the popper.js script for cookie consent.');
        };
        document.body.appendChild(popperScript);
        // }
    }
  }

  async loadGoogleAnalytics(): Promise<void> {
    const appSettingCode = 'google_analytics_id';

    const appSetting: AppSetting = await this.appSettingService.getFromCode(appSettingCode);

    if (appSetting?.value) {
      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-7R3GHPVVL8';
      document.head.appendChild(script1);

      const script2 = document.createElement('script');

      script2.innerHTML =
        'window.dataLayer = window.dataLayer || [];' +
        'function gtag(){dataLayer.push(arguments);}' +
        "gtag('js', new Date());" +
        "gtag('config', 'G-7R3GHPVVL8');";

      document.head.appendChild(script2);
    }
  }

  initPushNotifications(): void {
    if (this.pushInitiatedForUserId !== this.currentUser?.id) {
      this.cloudMessagingService.requestPermissionsForCurrentUser();
      // this.cloudMessagingService.initPushFeature(this.currentUser.id);
      this.pushInitiatedForUserId = this.currentUser.id;
    }
  }
}
