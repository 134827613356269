import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { GraphQLModule } from './graphql.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
  CONFIG
} from '@angular/fire/analytics';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule } from 'ngx-timeago';

import { environment } from '../environments/environment.prod';
// import {environment} from '../environments/environment';

declare const window: any;
window.environment = environment;

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';

import { SideMenuPageModule } from './side-menu/side-menu.module';
import { WelcomePageModule } from './welcome/welcome.module';
import { LoginPageModule } from './login/login.module';
import { RegisterPageModule } from './register/register.module';
import { SetPasswordPageModule } from './set-password/set-password.module';
import { ForgotPasswordPageModule } from './forgot-password/forgot-password.module';
import { LeaveCommentModule } from './components/charging-station-view/contribute/modals/leave-comment/leave-comment.module';
import { TakePhotoModule } from './components/charging-station-view/contribute/modals/take-photo/take-photo.module';
import { SuggestUpdateModule } from './components/charging-station-view/contribute/modals/suggest-update/suggest-update.module';
import { EvaluateModule } from './components/charging-station-view/contribute/modals/evaluate/evaluate.module';
import { QuotationEditPageModule } from './app/profile/quotations/quotation-edit/quotation-edit.module';
import { Stripe } from '@capacitor-community/stripe';

import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';

export const httpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');
registerLocaleData(localeFr);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot({
      backButtonIcon: '',
      backButtonText: '',
      menuIcon: 'menu',
      animated: true
    }),
    AppRoutingModule,
    GraphQLModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TimeagoModule.forRoot({
      formatter: {
        provide: TimeagoFormatter,
        useClass: TimeagoCustomFormatter
      }
    }),
    FontAwesomeModule,
    LeaveCommentModule,
    TakePhotoModule,
    SuggestUpdateModule,
    EvaluateModule,
    SideMenuPageModule,
    WelcomePageModule,
    LoginPageModule,
    RegisterPageModule,
    SetPasswordPageModule,
    ForgotPasswordPageModule,
    QuotationEditPageModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'fr' },
    TimeagoIntl,
    SafariViewController,
    Clipboard,
    LaunchNavigator
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
