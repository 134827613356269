import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

import { ChargingStation } from '../models/charging-station.interface';
import { UserChargingStationFavorite } from '../models/user-charging-station-favorite.interface';

import { User } from '../models/user.interface';

import { GraphqlService } from './graphql.service';
import { UserService } from './user.service';
import { Observable, Subscription } from 'rxjs';
import { ChargingPointReservation } from '../models/charging-point-reservation.interface';
import { SpotFilters } from '../models/spot-filters.interface';
import { Marker } from '../models/marker.interface';

@Injectable({
  providedIn: 'root'
})
export class ChargingStationService {
  collectionNamePlural = 'chargingStations';
  collectionNameSingular = 'chargingStation';

  graphqlQueries: {
    getAllItems: string;
    getAllItemsWithCache: string;
    getItem: string;
    getAvailableFrames: string;
    getAvailableFramesOfDay: string;
    makeChargeReservation: string;
    deleteItem: string;
    updateItem: string;
    addItem: string;
    addImageUrl: string;
    searchItemsInsideCoordinates: string;
    addChargingStationToUser: string;
    removeChargingStationToUser: string;
    getAllFromUserId: string;
    disable: string;
    enable: string;
    setInterestAroundToChargingStation: string;
  } = {
    getAllItems: `
		  query getAll(
        $query: String,
        $page: Int,
        $itemsPerPage: Int,
        $orderBy: String,
        $direction: String,
        $filters: JSON,
        $currentUserCoordinates: JSON,
        $isDisabled: Boolean,
        $isPro: Boolean,
      ) {
		    chargingStations(
          page: $page,
          itemsPerPage: $itemsPerPage,
          orderBy: $orderBy,
          direction: $direction,
          query: $query
          filters: $filters,
          currentUserCoordinates: $currentUserCoordinates,
          isDisabled: $isDisabled,
          isPro: $isPro
        ) {
          id
          idStationItinerance
          title
          slug
          implantationStation
          adresseStation
          codeInseeCommune
          location
          longitude
          latitude
          nbrePdc
          gratuit
          paiementActe
          paiementCb
          paiementAutre
          tarification
          conditionAcces
          reservation
          horaires
          accessibilitePmr
          restrictionGabarit
          stationDeuxRoues
          raccordement
          numPdl
          dateMiseEnService
          observations
          dateMaj
          lastModified
          isPro
          imagesUrl
          centerOfInterestsLabel
          averageRatingScore
          isDisabled
          gireveChargingStationId
          cached_chargeModes

          googleMapPlace {
            photos
          }
		    }
		  }
		`,
    getItem: `
		  query getItem($id: ID!) {
		    chargingStation(id: $id) {
          id
          chargingNetworkId
          chargingNetwork {
            name
          }
          userId
          user {
            id
            username
            profilePictureUrl
          }
          averageRatingScore
          isDisabled
          totalRatings
          idStationItinerance
          title
          slug
          implantationStation
          adresseStation
          codeInseeCommune
          location
          longitude
          latitude
          nbrePdc
          gratuit
          paiementActe
          paiementCb
          paiementAutre
          tarification
          conditionAcces
          reservation
          horaires
          accessibilitePmr
          restrictionGabarit
          stationDeuxRoues
          raccordement
          numPdl
          dateMiseEnService
          observations
          dateMaj
          lastModified
          isPro
          imagesUrl
          centerOfInterestsLabel
          googleMapPlaceId
          busystatus
          busystatusuntil
          availabilitystatus
          availabilitystatusuntil
          useabilitystatus
          useabilitystatusuntil
          isAvailable
          gireveChargingStationId
          cached_chargeModes

          googleMapPlace {
            id
            place_id
            address_components
            adr_address
            business_status
            formatted_address
            formatted_phone_number
            geometry
            icon
            icon_background_color
            icon_mask_base_uri
            international_phone_number
            name
            opening_hours
            permanently_closed
            photos
            place_id
            plus_code
            price_level
            rating
            reviews
            types
            url
            user_ratings_total
            utc_offset
            vicinity
            website
          }

          googleMapPlacesInterestAroundPlaces

          pointPowerPlugs {
            id
            powerPlugId {
              id
              title
            }
            chargingPointId {
              id
              name
              price
              minPrice
              maxPrice
            }
          }

          powerPlugsWithPuissanceNominale {
            puissanceNominale
            powerPlug {
              id
              title
              imageUrl
            }
            chargeMode
            count
            countByStatus
          }

          chargingPoints {
            id
            idPdcItinerance
            idPdcLocal
            puissanceNominale
            price
            minPrice
            maxPrice
            name
            description
            imagesUrl
            powerPlugIds

            chargeDayFrames
            carshareDayFrames
            carshare

            chargeAvaibilities {
              day
              startingHour
              endingHour
              startingMinute
              endingMinute
            }

            carshareAvaibilities {
              day
              startingHour
              endingHour
              startingMinute
              endingMinute
            }


            services {
              description
              price
              id
              isCarsharing
              carSharingDestination
            }

            chargingPointPowerPlugs {
              id
              powerPlugId
              chargingPointId
            }
          }
        }
		  }
		`,
    getAvailableFrames: `
      query getAvailableFrames($startingFrom: DateTime, $chargingPointPowerPlugId: String, $chargingStationId: String) {
        availableFrames(startingFrom: $startingFrom,
          chargingPointPowerPlugId: $chargingPointPowerPlugId, chargingStationId: $chargingStationId)
      }
		`,
    getAvailableFramesOfDay: `
      query getAvailableFramesOfDay($date: String, $chargingPointPowerPlugId: String, $chargingStationId: String) {
        availableFramesOfDay(date: $date,
          chargingPointPowerPlugId: $chargingPointPowerPlugId, chargingStationId: $chargingStationId)
      }
		`,
    deleteItem: `
			mutation deleteItem($id: ID!) {
				deleteChargingStation(id: $id) {
					id
				}
			}
		`,
    updateItem: `
			mutation updateItem(
        $id: ID!,
        $chargingNetworkId: String,
        $idStationItinerance: String,
        $title: String,
        $implantationStation: String,
        $adresseStation: String,
        $codeInseeCommune: String,
        $latitude: Float,
        $longitude: Float,
        $nbrePdc: Int,
        $gratuit: Boolean,
        $paiementActe: Boolean,
        $paiementCb: Boolean,
        $paiementAutre: String,
        $tarification: String,
        $conditionAcces: String,
        $reservation: Boolean,
        $horaires: String,
        $accessibilitePmr: String,
        $restrictionGabarit: String,
        $stationDeuxRoues: Boolean,
        $raccordement: String,
        $numPdl: String,
        $dateMiseEnService: String,
        $observations: String,
        $dateMaj: String,
        $lastModified: DateTime,
        $isPro: Boolean,
        $imagesUrl: [String],
        $googleMapPlaceId: String,
        $chargingPoints: JSON
      ) {
				updateChargingStation(
          id: $id,
          chargingNetworkId: $chargingNetworkId,
          idStationItinerance: $idStationItinerance,
          title: $title,
          implantationStation: $implantationStation,
          adresseStation: $adresseStation,
          codeInseeCommune: $codeInseeCommune,
          latitude: $latitude,
          longitude: $longitude,
          nbrePdc: $nbrePdc,
          gratuit: $gratuit,
          paiementActe: $paiementActe,
          paiementCb: $paiementCb,
          paiementAutre: $paiementAutre,
          tarification: $tarification,
          conditionAcces: $conditionAcces,
          reservation: $reservation,
          horaires: $horaires,
          accessibilitePmr: $accessibilitePmr,
          restrictionGabarit: $restrictionGabarit,
          stationDeuxRoues: $stationDeuxRoues,
          raccordement: $raccordement,
          numPdl: $numPdl,
          dateMiseEnService: $dateMiseEnService,
          observations: $observations,
          dateMaj: $dateMaj,
          lastModified: $lastModified,
          isPro: $isPro,
          imagesUrl: $imagesUrl,
          googleMapPlaceId: $googleMapPlaceId,
          chargingPoints: $chargingPoints
        ) {
					id
				}
			}
		`,
    addItem: `
			mutation addItem(
        $chargingNetworkId: String,
        $idStationItinerance: String,
        $title: String,
        $implantationStation: String,
        $adresseStation: String,
        $codeInseeCommune: String,
        $latitude: Float,
        $longitude: Float,
        $nbrePdc: Int,
        $gratuit: Boolean,
        $paiementActe: Boolean,
        $paiementCb: Boolean,
        $paiementAutre: String,
        $tarification: String,
        $conditionAcces: String,
        $reservation: Boolean,
        $horaires: String,
        $accessibilitePmr: String,
        $restrictionGabarit: String,
        $stationDeuxRoues: Boolean,
        $raccordement: String,
        $numPdl: String,
        $dateMiseEnService: String,
        $observations: String,
        $dateMaj: String,
        $lastModified: DateTime,
        $isPro: Boolean,
        $imagesUrl: [String],
        $googleMapPlaceId: String,
        $chargingPoints: JSON
        ) {
				addChargingStation(
          chargingNetworkId: $chargingNetworkId,
          idStationItinerance: $idStationItinerance,
          title: $title,
          implantationStation: $implantationStation,
          adresseStation: $adresseStation,
          codeInseeCommune: $codeInseeCommune,
          latitude: $latitude,
          longitude: $longitude,
          nbrePdc: $nbrePdc,
          gratuit: $gratuit,
          paiementActe: $paiementActe,
          paiementCb: $paiementCb,
          paiementAutre: $paiementAutre,
          tarification: $tarification,
          conditionAcces: $conditionAcces,
          reservation: $reservation,
          horaires: $horaires,
          accessibilitePmr: $accessibilitePmr,
          restrictionGabarit: $restrictionGabarit,
          stationDeuxRoues: $stationDeuxRoues,
          raccordement: $raccordement,
          numPdl: $numPdl,
          dateMiseEnService: $dateMiseEnService,
          observations: $observations,
          dateMaj: $dateMaj,
          lastModified: $lastModified,
          isPro: $isPro,
          imagesUrl: $imagesUrl
          googleMapPlaceId: $googleMapPlaceId,
          chargingPoints: $chargingPoints
        ) {
					id
				}
			}
		`,
    makeChargeReservation: `
			mutation makeChargeReservation(
        $desiredEndDate: String!,
        $desiredStartDate: String!,
        $desiredStartTime: String!,
        $desiredEndTime: String!,
        $chargingPointPowerPlugId: String!,
        $userId: String!,
        $services: [JSON]!,
        $card: String!,
        $price: Float!,
      ) {
				makeChargeReservation(
          desiredEndDate: $desiredEndDate,
          desiredStartDate: $desiredStartDate,
          desiredStartTime: $desiredStartTime,
          desiredEndTime: $desiredEndTime,
          userId: $userId,
          chargingPointPowerPlugId: $chargingPointPowerPlugId
          services: $services
          card: $card
          price: $price
        ) {
					id
				}
			}
		`,
    addImageUrl: `
			mutation addImageUrl(
        $id: String,
        $imageUrl: String
      ) {
				addImageUrl(
          id: $id,
          imageUrl: $imageUrl
        ) {
					id
          imagesUrl
				}
			}
		`,
    searchItemsInsideCoordinates: `
      query searchItemsInsideCoordinates($coordinates: String!, $zoomLevel: Int!, $requestedPage: Int!,
        $filters: JSON, $isDisabled: Boolean) {
        searchItemsInsideCoordinates(coordinates: $coordinates, zoomLevel: $zoomLevel,
          requestedPage: $requestedPage, filters: $filters, isDisabled: $isDisabled)
      }
    `,
    getAllItemsWithCache: `
      query getAllItemsWithCache {
        getAllWithCache {
          id
          chargingNetworkId
          title
          slug
          longitude
          latitude
        }
      }
    `,
    addChargingStationToUser: `
			mutation addChargingStationToUser(
				$chargingStationId: String!,
				$userId: String!,
			) {
				addChargingStationToUser(
					chargingStationId: $chargingStationId,
					userId: $userId,
				) {
					id
					chargingStationId
					userId
				}
			}
		`,
    removeChargingStationToUser: `
			mutation removeChargingStationToUser(
				$chargingStationId: String!,
				$userId: String!,
			) {
				removeChargingStationToUser(
					chargingStationId: $chargingStationId,
					userId: $userId,
				) {
					id
				}
			}
		`,
    getAllFromUserId: `
		  query getAllFromUserId($userId: String!, $isDisabled: Boolean) {
		    chargingStations(userId: $userId, isDisabled: $isDisabled) {
          id
          chargingNetworkId
          chargingNetwork {
            name
          }
          userId
          averageRatingScore
          isDisabled
          totalRatings
          idStationItinerance
          title
          slug
          implantationStation
          adresseStation
          codeInseeCommune
          location
          longitude
          latitude
          nbrePdc
          gratuit
          paiementActe
          paiementCb
          paiementAutre
          tarification
          conditionAcces
          reservation
          horaires
          accessibilitePmr
          restrictionGabarit
          stationDeuxRoues
          raccordement
          numPdl
          dateMiseEnService
          observations
          dateMaj
          lastModified
          isPro
          imagesUrl
          centerOfInterestsLabel
          googleMapPlaceId
          gireveChargingStationId

          googleMapPlace {
            id
            place_id
            address_components
            adr_address
            business_status
            formatted_address
            formatted_phone_number
            geometry
            icon
            icon_background_color
            icon_mask_base_uri
            international_phone_number
            name
            opening_hours
            permanently_closed
            photos
            place_id
            plus_code
            price_level
            rating
            reviews
            types
            url
            user_ratings_total
            utc_offset
            vicinity
            website
          }

          googleMapPlacesInterestAroundPlaces

          pointPowerPlugs {
            id
            powerPlugId {
              id
              title
            }
            chargingPointId {
              id
              name
              price
              minPrice
              maxPrice
            }
          }

          powerPlugsWithPuissanceNominale {
            puissanceNominale
            powerPlug {
              id
              title
            }
            chargeMode
            count
            countByStatus
          }

          chargingPoints {
            id
            idPdcItinerance
            idPdcLocal
            puissanceNominale
            price
            minPrice
            maxPrice
            name
            description
            imagesUrl

            chargeDayFrames
            carshareDayFrames
            carshare

            chargeAvaibilities {
              day
              startingHour
              endingHour
              startingMinute
              endingMinute
            }
            
            carshareAvaibilities {
              day
              startingHour
              endingHour
              startingMinute
              endingMinute
            }

            services {
              description
              price
              id
              isCarsharing
              carSharingDestination
            }

            chargingPointPowerPlugs {
              id
              powerPlugId
              chargingPointId
            }
          } 
        }
		  }
		`,
    disable: `
			mutation disableChargingStation(
        $id: ID!,
      ) {
				disableChargingStation(
          id: $id,
        ) {
					id
				}
			}
		`,
    enable: `
			mutation enableChargingStation(
        $id: ID!,
      ) {
				enableChargingStation(
          id: $id,
        ) {
					id
				}
			}
		`,
    setInterestAroundToChargingStation: `
      mutation setInterestAroundToChargingStation(
        $id: ID!,
      ) {
        setInterestAroundToChargingStation(
          id: $id,
        ) {
          id
          chargingNetworkId
          chargingNetwork {
            name
          }
          userId
          averageRatingScore
          isDisabled
          totalRatings
          idStationItinerance
          title
          slug
          implantationStation
          adresseStation
          codeInseeCommune
          location
          longitude
          latitude
          nbrePdc
          gratuit
          paiementActe
          paiementCb
          paiementAutre
          tarification
          conditionAcces
          reservation
          horaires
          accessibilitePmr
          restrictionGabarit
          stationDeuxRoues
          raccordement
          numPdl
          dateMiseEnService
          observations
          dateMaj
          lastModified
          isPro
          imagesUrl
          centerOfInterestsLabel
          googleMapPlaceId

          googleMapPlace {
            id
            place_id
            address_components
            adr_address
            business_status
            formatted_address
            formatted_phone_number
            geometry
            icon
            icon_background_color
            icon_mask_base_uri
            international_phone_number
            name
            opening_hours
            permanently_closed
            photos
            place_id
            plus_code
            price_level
            rating
            reviews
            types
            url
            user_ratings_total
            utc_offset
            vicinity
            website
          }

          googleMapPlacesInterestAroundPlaces

          pointPowerPlugs {
            id
            powerPlugId {
              id
              title
            }
            chargingPointId {
              id
              name
              price
              minPrice
              maxPrice
            }
          }

          powerPlugsWithPuissanceNominale {
            puissanceNominale
            powerPlug {
              id
              title
            }
            chargeMode
            count
            countByStatus
          }

          chargingPoints {
            id
            idPdcItinerance
            idPdcLocal
            puissanceNominale
            price
            minPrice
            maxPrice
            name
            description
            imagesUrl

            chargeDayFrames
            carshareDayFrames
            carshare

            chargeAvaibilities {
              day
              startingHour
              endingHour
              startingMinute
              endingMinute
            }

            carshareAvaibilities {
              day
              startingHour
              endingHour
              startingMinute
              endingMinute
            }


            services {
              description
              price
              id
              isCarsharing
              carSharingDestination
            }

            chargingPointPowerPlugs {
              id
              powerPlugId
              chargingPointId
            }
          }
        }
      }
    `
  };

  currentUser: User;

  constructor(private graphqlService: GraphqlService, private userService: UserService) {
    this.userService.currentUserObservable.subscribe(
      (currentUser: User) => (this.currentUser = currentUser)
    );
  }

  async add(data: ChargingStation): Promise<ChargingStation> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.graphqlQueries.addItem, data);

      if (result.addChargingStation) {
        return result.addChargingStation as ChargingStation;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async makeChargeReservation(data: any): Promise<ChargingPointReservation> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.graphqlQueries.makeChargeReservation,
        data
      );

      if (result.chargingPointReservation) {
        return result.chargingPointReservation as ChargingPointReservation;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getFromId(id: string): Promise<ChargingStation> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(this.graphqlQueries.getItem, {
        id
      });
      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as ChargingStation;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAvailableFrames(params: any): Promise<ChargingStation> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.graphqlQueries.getAvailableFrames,
        params
      );
      return result.availableFrames;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAvailableFramesOfDay(params: any): Promise<ChargingStation> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.graphqlQueries.getAvailableFramesOfDay,
        params
      );
      return result.availableFramesOfDay;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async update(data: ChargingStation): Promise<ChargingStation> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.graphqlQueries.updateItem,
        data
      );

      if (result[this.collectionNameSingular]) {
        return result[this.collectionNameSingular] as ChargingStation;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async delete(id: string): Promise<void> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.graphqlQueries.deleteItem, {
        id
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async disable(id: string): Promise<void> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.graphqlQueries.disable, {
        id
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async enable(id: string): Promise<void> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.graphqlQueries.enable, {
        id
      });
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async setInterestAroundToChargingStation(id: string): Promise<ChargingStation> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.graphqlQueries.setInterestAroundToChargingStation,
        {
          id
        }
      );

      if (result.setInterestAroundToChargingStation) {
        return result.setInterestAroundToChargingStation as ChargingStation;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAll(data: any): Promise<ChargingStation[]> {
    //console.log(data);
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.graphqlQueries.getAllItems,
        data
      );

      const items: ChargingStation[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as ChargingStation);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  searchItemsInsideCoordinates(
    westLng: number,
    southLat: number,
    eastLng: number,
    northLat: number,
    zoomLevel: number,
    requestedPage: number = 0,
    filters: SpotFilters = null,
    isDisabled: boolean = null
  ): Observable<any> {
    try {
      return this.graphqlService.apolloWatchQueryObservable(
        this.graphqlQueries.searchItemsInsideCoordinates,
        {
          coordinates: JSON.stringify([westLng, southLat, eastLng, northLat]),
          zoomLevel,
          requestedPage,
          filters,
          isDisabled
        }
      );
    } catch (err) {
      console.error(err);
    }
  }

  async getAllWithCache(): Promise<ChargingStation[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.graphqlQueries.getAllItemsWithCache
      );

      const items: ChargingStation[] = [];

      if (result.getAllWithCache) {
        for (const item of result.getAllWithCache) {
          items.push(item as ChargingStation);
        }
      }

      return items;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async addChargingStationToFavoriteByCurrentUser(
    chargingStationId: string
  ): Promise<UserChargingStationFavorite> {
    if (this.currentUser) {
      return await this.addChargingStationToFavoriteFromUser(
        chargingStationId,
        this.currentUser.id
      );
    } else {
      return Promise.reject({
        message: 'No current user'
      });
    }
  }

  async addChargingStationToFavoriteFromUser(
    chargingStationId: string,
    userId: string
  ): Promise<UserChargingStationFavorite> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.graphqlQueries.addChargingStationToUser,
        {
          chargingStationId,
          userId
        }
      );

      if (result.addChargingStationToUser) {
        return result.addChargingStationToUser as UserChargingStationFavorite;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async addImageUrl(id: string, imageUrl: string): Promise<string[]> {
    try {
      const result: any = await this.graphqlService.apolloMutate(this.graphqlQueries.addImageUrl, {
        id,
        imageUrl
      });

      return result.addImageUrl.imagesUrl as string[];
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async removeChargingStationToFavoriteByCurrentUser(
    chargingStationId: string
  ): Promise<UserChargingStationFavorite> {
    if (this.currentUser) {
      return await this.removeChargingStationToFavoriteFromUser(
        chargingStationId,
        this.currentUser.id
      );
    } else {
      return Promise.reject({
        message: 'No current user'
      });
    }
  }

  async removeChargingStationToFavoriteFromUser(
    chargingStationId: string,
    userId: string
  ): Promise<UserChargingStationFavorite> {
    try {
      const result: any = await this.graphqlService.apolloMutate(
        this.graphqlQueries.removeChargingStationToUser,
        {
          chargingStationId,
          userId
        }
      );

      if (result.removeChargingStationToUser) {
        return result.removeChargingStationToUser as UserChargingStationFavorite;
      }
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async getAllFromUserId(userId: string): Promise<ChargingStation[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.graphqlQueries.getAllFromUserId,
        {
          userId,
          isDisabled: false
        }
      );

      const items: ChargingStation[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as ChargingStation);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  async search(data: any): Promise<ChargingStation[]> {
    try {
      const result: any = await this.graphqlService.apolloWatchQuery(
        this.graphqlQueries.getAllItems,
        data
      );

      const items: ChargingStation[] = [];

      if (result[this.collectionNamePlural]) {
        for (const item of result[this.collectionNamePlural]) {
          items.push(item as ChargingStation);
        }
      }

      return items;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }
}
