import { Injectable } from '@angular/core';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UrlOpenerService {
  constructor(private platform: Platform, private safariViewController: SafariViewController) {}

  openUrl(url: string): void {
    if (this.platform.is('hybrid')) {
      this.safariViewController.isAvailable().then((available: boolean) => {
        if (available) {
          this.safariViewController
            .show({
              url
            })
            .subscribe(
              (result: any) => {
                //console.log(result);
                if (result.event === 'opened') {
                  //console.log('URL ' + url + ' opened');
                } else if (result.event === 'loaded') {
                  //console.log('URL ' + url + ' loaded');
                } else if (result.event === 'closed') {
                  //console.log('URL ' + url + ' closed');
                }
              },
              (error: any) => {
                console.error(error);
                window.open(url, '_system');
              }
            );
        } else {
          window.open(url, '_system');
        }
      });
    } else {
      window.open(url, '_blank');
    }
  }

  openUrlWithSystem(url: string): void {
    //console.log('openUrlWithSystem', url);
    window.location.href = url;
  }
}
