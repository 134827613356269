import { Component, OnInit, NgZone } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { NavController, AlertController, MenuController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from '../services/user.service';
import { SideMenuService } from '../services/side-menu.service';
import { User } from '../models/user.interface';

import firebase from 'firebase/app';

@Component({
  selector: 'app-welcome',
  templateUrl: 'welcome.page.html',
  styleUrls: ['welcome.page.scss']
})
export class WelcomePage implements OnInit {
  form: FormGroup;
  sending = false;

  constructor(
    private formBuilder: FormBuilder,
    private alertController: AlertController,
    private router: Router,
    private zone: NgZone,
    private navCtrl: NavController,
    private userService: UserService,
    private sideMenuService: SideMenuService,
    private menuCtrl: MenuController
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  async appleSignIn(): Promise<void> {
    this.form.markAllAsTouched();

    this.sending = true;

    this.form.disable();

    try {
      const result: {
        user: User;
        userCredential: firebase.auth.UserCredential;
      } = await this.userService.loginWithApple();
      //console.log(result);
      this.sending = false;
      this.form.enable();
      this.form.reset();

      if (result.user) {
        this.goToRoot();
      } else {
        window.localStorage.setItem('userCredential', JSON.stringify(result.userCredential));

        this.goForward('register');
      }
    } catch (err) {
      //console.log(err);

      alert(err.message);

      this.sending = false;
      this.form.enable();
    }
  }

  async submitForm(): Promise<void> {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.sending = true;
      this.form.disable();

      window.localStorage.setItem('emailFilledIn', this.form.value.email);

      try {
        const result: {
          exist: boolean;
          hasAuthConfigured: boolean;
        } = await this.userService.doesUserExistsWithEmail(this.form.value.email);

        this.sending = false;
        this.form.enable();
        //console.log(result);
        if (result.exist) {
          if (result.hasAuthConfigured) {
            const user = await this.userService.getUserByEmail(window.localStorage.getItem('emailFilledIn'));
            //console.log('user ', user);
            if(user.emailIsVerified) {
              this.goForward('login');
            } else {
              const message = 'Pour finaliser ton inscription, tu dois d\'abord cliquer sur le lien de confirmation que nous t\'avons envoyé par email. 😉';
              const alert = await this.alertController.create({
                message,
                buttons: [
                  {
                    text: 'RENVOYER',
                    handler: () => {
                        this.userService.resendVerificationEmail(this.form.value.email);
                    }
                  },
                  {
                      text: 'OK',
                      cssClass: 'success'
                  }
                ]
              });

              await alert.present();
              this.sideMenuService.goToRoot();
              this.sideMenuService.closeMenu();
            }
          } else {
            this.goForward('set-password');
          }
        } else {
          this.goForward('register');
        }
      } catch (err) {
        //console.log(err);

        this.sending = false;
        this.form.enable();
      }
    }
  }

  goBack(): void {
    this.sideMenuService.goToRoot();
  }

  goForward(page: string): void {
    this.sideMenuService.navigateForward(page);
  }

  goToRoot(): void {
    this.sideMenuService.goToRoot();
  }

  closeMenuAndNavigateTo(url: string): void {
    this.menuCtrl.close();

    this.router.navigate([url]);
  }

  async googleSignIn(): Promise<void> {
    this.form.markAllAsTouched();

    this.sending = true;

    this.form.disable();

    try {
      const result: {
        user: User;
        userCredential: firebase.auth.UserCredential;
      } = await this.userService.loginWithGoogle();
      //console.log(result);
      this.sending = false;
      this.form.enable();
      this.form.reset();

      if (result.user) {
        this.goToRoot();
      } else {
        window.localStorage.setItem('userCredential', JSON.stringify(result.userCredential));

        this.goForward('register');
      }
    } catch (err) {
      //console.log(err);

      alert(err.message);

      this.sending = false;
      this.form.enable();
    }
  }

  async facebookSignIn(): Promise<void> {
    this.form.markAllAsTouched();

    this.sending = true;

    this.form.disable();

    try {
      const result: {
        user: User;
        userCredential: firebase.auth.UserCredential;
      } = await this.userService.loginWithFacebook();
      //console.log(result);
      this.sending = false;
      this.form.enable();
      this.form.reset();

      if (result.user) {
        this.goToRoot();
      } else {
        window.localStorage.setItem('userCredential', JSON.stringify(result.userCredential));

        this.goForward('register');
      }
    } catch (err) {
      //console.log(err);

      alert(err.message);

      this.sending = false;
      this.form.enable();
    }
  }
}
