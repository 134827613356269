import { User } from './user.interface';

export interface Notification {
  id: string;
  title: string;
  type: string;
  userId: string;
  user: User;
  read: boolean;
  collectionName: string;
  docId: string;
  fromUserId?: string;
  fromUserUsername?: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum NotificationType {
  askUserToStartCharge = 'ask-user-to-start-charge',
  askUserToEndCharge = 'ask-user-to-end-charge',
  askChargingStationReview = 'ask-charging-station-review',
  chat = 'chat',
  quotationProposed = 'quotation-proposed',
  quotationAcceptedByRequestingUser = 'quotation-accepted-by-requesting-user',
  quotationRejectedByRequestingUser = 'quotation-rejected-by-requesting-user',
  approvedByQuotationAuthor = 'quotation-approved-by-quotation-author',
  rejectedByQuotationAuthor = 'quotation-rejected-by-quotation-author',
  friendshipRequest = 'friendship-request',
  friendshipAccepted = 'friendship-accepted',
  friendshipRejected = 'friendship-rejected',
  newChargeReservation = 'new-charge-reservation',
  chargeReservationCancelledByOwner = 'charge-reservation-cncelled-by-owner',
  chargeReservationCancelledByCustomer = 'charge-reservation-cncelled-by-customer'
}

export const getNotificationUrl = (notification: Notification): string => {
  //console.log(
    //'🚀 ~ file: notification.interface.ts ~ line 28 ~ getNotificationUrl ~ notification',
    //notification
  //);
  switch (notification.type) {
    case NotificationType.chat:
      return '/messages/' + notification.docId;
    case NotificationType.friendshipRequest:
    case NotificationType.friendshipAccepted:
    case NotificationType.friendshipRejected:
      //return '/member/' + notification.fromUserUsername;
      return '/messages/friends';
    case NotificationType.newChargeReservation:
      return '/profile/charging-stations/booking';
    case NotificationType.chargeReservationCancelledByOwner:
    case NotificationType.chargeReservationCancelledByCustomer:
    case NotificationType.askUserToStartCharge:
    case NotificationType.askUserToEndCharge:
      return '/profile/booking';
    case NotificationType.quotationProposed:
    case NotificationType.approvedByQuotationAuthor:
    case NotificationType.rejectedByQuotationAuthor:
      return '/profile/quotes/details/' + notification.docId;
    case NotificationType.quotationAcceptedByRequestingUser:
    case NotificationType.quotationRejectedByRequestingUser:
      return '/profile/quotes-submission/details/' + notification.docId;
    default:
      return null;
  }
};
