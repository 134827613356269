import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private title: Title, private meta: Meta) {}

  setDefaultSeo(): void {
    if (this.title.getTitle() === '') {
      this.updateAllTitle('spotNcharge - Borne simulateur');
      this.updateAllDescription(
        "L'app dédiée à la recharge des voitures électriques : Trouve les bornes publiques et partagées, calcule les temps de recharge, compare les tarifs et planifie les itinéraires."
      );
    }
  }

  updateAllTitle(title: string) {
    this.updateTitle(title);
    this.updateDcTitle(title);
  }

  updateAllDescription(title: string) {
    this.updateDescription(title);
    this.updateDcDescription(title);
    this.updateOgDescription(title);
  }

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url });
  }

  updateDcTitle(text: string) {
    this.meta.updateTag({ name: 'dc.title', content: text });
  }

  updateDcDescription(text: string) {
    this.meta.updateTag({ name: 'dc.description', content: text });
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  updateOgDescription(text: string) {
    this.meta.updateTag({ name: 'og:description', content: text });
  }

}
