import { Injectable, NgZone } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { NavController } from '@ionic/angular';

import { UserService } from './user.service';
import { SideMenuService } from './side-menu.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardAppService implements CanActivate {
  constructor(
    private navCtrl: NavController,
    private sideMenuService: SideMenuService,
    private userService: UserService,
    private router: Router,
    private zone: NgZone
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // Utiliser const ici et un flag pour contrôler le désabonnement.
      const subscription: Subscription = this.userService.isLoggedObservable.subscribe({
        next: (isLogged: boolean) => {
          if (isLogged) {
            resolve(true);  // Utilisateur connecté, l'accès est autorisé
          } else {
            this.zone.run(() => {
              this.navCtrl.navigateRoot(['/']); // Redirige vers la page d'accueil si non-admin
            });
          resolve(false);  // Refuser l'accès
          }
        },
        error: err => {
          reject(err);
        },
        complete: () => {
          // Utiliser le hook complete pour se désabonner de manière sécurisée
          subscription.unsubscribe();
        }
      });
    });
  }}
