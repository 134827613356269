import { Injectable } from '@angular/core';
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask
} from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class UploadDocumentService {
  constructor(private afStorage: AngularFireStorage) {}

  upload(
    folder: string = '',
    filename: string = null,
    extension: string = '',
    file: any,
    encoding: string = 'raw'
  ): {
    ref: AngularFireStorageReference;
    task: AngularFireUploadTask;
  } {
    // Create a reference to file
    if (!filename) {
      filename = this.generateUUID();
    }

    const ref = this.afStorage.ref(folder + '/' + filename + extension);

    return {
      ref,
      task: ref.putString(file, encoding)
    };
  }

  deleteFile(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const ref: AngularFireStorageReference = this.afStorage.refFromURL(url);

      const sub = ref.delete().subscribe((result: any) => {
        sub.unsubscribe();
      });

      resolve();
    });
  }

  private generateUUID(): string {
    let d: number = new Date().getTime();

    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      // eslint-disable-next-line no-bitwise
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      // eslint-disable-next-line no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });

    return uuid;
  }
}
