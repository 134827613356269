import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AlertController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { PushNotifications, Token, PushNotificationSchema, ActionPerformed } from '@capacitor/push-notifications';
import { Notification, getNotificationUrl } from 'src/app/models/notification.interface';
import { User } from 'src/app/models/user.interface';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CloudMessagingService {
  currentUser: User;
  isMessageOpened: boolean = false;

  constructor(
    private platform: Platform,
    private afMessaging: AngularFireMessaging,
    private alertController: AlertController,
    private userService: UserService,
    private router: Router
  ) {
    this.userService.currentUserObservable.subscribe((user: User) => {
      this.currentUser = user;
    });
  }

  requestPermissionsForCurrentUser(): void {
    if (this.platform.is('capacitor')) {
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          this.clearBadgeNumber();
          PushNotifications.register();
        } else {
          console.error('Push notification permission was denied.');
        }
      }).catch(error => console.error('Error requesting push notifications permissions:', error));
    } else {
      this.afMessaging.requestToken.subscribe(
        token => {
          this.saveTokenToFirestore(token)
            .then(() => this.initListeners())
            .catch(err => {
              console.error('Error saving token to Firestore:', err);
              this.initListeners();
            });
        },
        error => console.error('Failed to get token for push notifications:', error)
      );
    }
  }

  initListeners(): void {
    if (this.platform.is('capacitor')) {
      PushNotifications.addListener('registration', (token: Token) => {
        this.saveTokenToFirestore(token.value);
      });

      PushNotifications.addListener('registrationError', (error: any) => {
        console.error('Error on push registration:', error);
      });

      PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
        this.showMessage(notification, notification.data, true);
      });

      PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
        this.showMessage(notification.notification, notification.notification.data);
      });
    } else {
      this.afMessaging.messages.pipe(
        tap((msg: any) => {
          this.showMessage(msg.notification as PushNotificationSchema, msg.data);
          this.clearBadgeNumber();
        })
      ).subscribe();
    }
  }

  clearBadgeNumber(): void {
    if (this.platform.is('capacitor')) {
      PushNotifications.removeAllDeliveredNotifications();
    }
  }

  async showMessage(
    payload: PushNotificationSchema,
    data: any,
    appOpened: boolean = false
  ): Promise<void> {
    if (!this.isMessageOpened) {
      this.isMessageOpened = true;

      const modal = await this.alertController.create({
        header: payload.title,
        message: payload.body?.replace(new RegExp('\r?\n', 'g'), '<br>'),
        buttons: [
          'Fermer',
          {
            text: 'Accéder',
            handler: () => {
              if (data?.type) {
                const url: string = getNotificationUrl(data as Notification);
                if (url) {
                  this.router.navigate([url]);
                }
              }
            }
          }
        ]
      });

      await modal.present();
      await modal.onDidDismiss();
      this.isMessageOpened = false;
    }
  }

  private saveTokenToFirestore(token: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (!token) {
        reject('No token to save');
      } else if (this.currentUser) {
        if (!this.currentUser.fcmTokens || !this.currentUser.fcmTokens.includes(token)) {
          await this.userService.addNewFcmTokenToCurrentUser(token);
        }
        resolve();
      } else {
        reject('User not logged in.');
      }
    });
  }
}
