import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { ChargingStationService } from 'src/app/services/charging-station.service';

import { SpotFilters } from 'src/app/models/spot-filters.interface';
import { Address } from 'src/app/models/address.interface';
import { ChargingStation } from 'src/app/models/charging-station.interface';
import { ToastController } from '@ionic/angular';
import { Marker } from '../models/marker.interface';

export enum SearchFor {
  spotDashboard = 'spot-dashboard',
  itinerary = 'itinerary'
}

@Injectable({
  providedIn: 'root'
})
export class SpotSearchService {
  currentSpotFilters: SpotFilters;
  currentSpotFiltersObservable: Observable<SpotFilters>;

  itinerarySpotFilters: SpotFilters;
  itinerarySpotFiltersObservable: Observable<SpotFilters>;

  searchingChargingStations = false;
  searchingChargingStationsObservable: Observable<boolean>;
  searchingChargingStationsSubscription: Subscription;

  currentSearchCoordinates: {
    topRightCorner: { lat: number; lng: number };
    bottomLeftCorner: { lat: number; lng: number };
    topLeftCorner: { lat: number; lng: number };
    bottomRightCorner: { lat: number; lng: number };
  } = {
    topRightCorner: { lat: 0, lng: 0 },
    bottomLeftCorner: { lat: 0, lng: 0 },
    topLeftCorner: { lat: 0, lng: 0 },
    bottomRightCorner: { lat: 0, lng: 0 }
  };
  currentSearchRadiusInKm = 100;

  chargingStations: ChargingStation[] = [];

  markers: Marker[] = [];

  loadedItems: string[] = [];
  chargingStationsObservable: Observable<ChargingStation[]>;
  markersObservable: Observable<Marker[]>;

  toastPresented = false;

  zoomLevel: number;

  currentSearchFor: SearchFor = SearchFor.spotDashboard;

  defaultSpotFilters: SpotFilters = {
    onlyAvailable: false,
    includeNotFree: false,
    includeFree: false,
    includePlugncharger: false,
    includeSuggestions: false,
    pointsOfInterest: [],
    powerPlugGroupsId: [],
    userCarId: null,
    carBrandId: null,
    carModelId: null,
    carModelVersionId: null,
    chargeModes: [],
    chargingNetworkId: null,
    chargingNetworkGroupId: null,
    emspsId: [],
    emspId: null
  } as SpotFilters;

  private currentSpotFiltersBehavior: BehaviorSubject<SpotFilters>;
  private itinerarySpotFiltersBehavior: BehaviorSubject<SpotFilters>;
  private chargingStationsBehavior: BehaviorSubject<ChargingStation[]>;
  private searchingChargingStationsBehavior: BehaviorSubject<boolean>;
  private markersBehavior: BehaviorSubject<Marker[]>;

  constructor(
    private chargingStationService: ChargingStationService,
    private toastController: ToastController
  ) {
    this.searchingChargingStationsBehavior = new BehaviorSubject<boolean>(false);
    this.searchingChargingStationsObservable =
      this.searchingChargingStationsBehavior.asObservable();
    this.searchingChargingStationsObservable.subscribe((searchingChargingStations: boolean) => {
      this.searchingChargingStations = searchingChargingStations;
    });

    this.currentSpotFiltersBehavior = new BehaviorSubject<SpotFilters>(null);
    this.currentSpotFiltersObservable = this.currentSpotFiltersBehavior.asObservable();
    this.currentSpotFiltersObservable.subscribe((currentSpotFilters: SpotFilters) => {
      this.currentSpotFilters = currentSpotFilters;

      this.forceRefreshCurrentSearch();
    });

    this.itinerarySpotFiltersBehavior = new BehaviorSubject<SpotFilters>(null);
    this.itinerarySpotFiltersObservable = this.itinerarySpotFiltersBehavior.asObservable();
    this.itinerarySpotFiltersObservable.subscribe((itinerarySpotFilters: SpotFilters) => {
      this.itinerarySpotFilters = itinerarySpotFilters;

      this.forceRefreshCurrentSearch();
    });

    this.markersBehavior = new BehaviorSubject<Marker[]>(null);
    this.markersObservable = this.markersBehavior.asObservable();
    this.markersObservable.subscribe((markers: Marker[]) => {
      this.markers = markers;
    });

    this.chargingStationsBehavior = new BehaviorSubject<ChargingStation[]>(null);
    this.chargingStationsObservable = this.chargingStationsBehavior.asObservable();
    this.chargingStationsObservable.subscribe((chargingStations: ChargingStation[]) => {
      this.chargingStations = chargingStations;
    });

    this.setCurrentSpotFilters(JSON.parse(JSON.stringify(this.defaultSpotFilters)) as SpotFilters);
    this.setItinerarySpotFilters(
      JSON.parse(JSON.stringify(this.defaultSpotFilters)) as SpotFilters
    );
    this.setChargingStations([] as ChargingStation[]);
  }

  setCurrentSpotFilters(currentSpotFilters: SpotFilters): void {
    this.currentSpotFiltersBehavior.next(currentSpotFilters);
  }

  setItinerarySpotFilters(itinerarySpotFilters: SpotFilters): void {
    this.itinerarySpotFiltersBehavior.next(itinerarySpotFilters);
  }

  setChargingStations(chargingStations: ChargingStation[]): void {
    this.chargingStationsBehavior.next(chargingStations);
  }

  setMarkers(markers: Marker[]): void {
    this.markersBehavior.next(markers);
  }

  setSearchingChargingStations(searchingChargingStations: boolean): void {
    this.searchingChargingStationsBehavior.next(searchingChargingStations);
  }

  updateFilters(newFilter: SpotFilters): void {
    this.setCurrentSpotFilters(Object.assign(this.currentSpotFilters, newFilter) as SpotFilters);
  }

  updateCurrentAddress(newAddress: Address): void {
    this.updateFilters({ address: newAddress } as SpotFilters);
  }

  async updatePolygonCoordinates(
    coordinates: {
      topRightCorner: google.maps.LatLng;
      bottomLeftCorner: google.maps.LatLng;
      topLeftCorner: google.maps.LatLng;
      bottomRightCorner: google.maps.LatLng;
    },
    zoomLevel: number,
    forceRefresh: boolean = false,
    searchFor: SearchFor = SearchFor.spotDashboard
  ): Promise<void> {
    this.currentSearchFor = searchFor;

    this.zoomLevel = zoomLevel;

    let changedCoordinates = false;

    for (const cornerName of [
      'topRightCorner',
      'bottomLeftCorner',
      'topLeftCorner',
      'bottomRightCorner'
    ]) {
      if (
        this.currentSearchCoordinates[cornerName].lat !== coordinates[cornerName].lat() ||
        this.currentSearchCoordinates[cornerName].lng !== coordinates[cornerName].lng()
      ) {
        changedCoordinates = true;
      }

      this.currentSearchCoordinates[cornerName].lat = coordinates[cornerName].lat();
      this.currentSearchCoordinates[cornerName].lng = coordinates[cornerName].lng();
    }

    if (forceRefresh || changedCoordinates) {
      this.forceRefreshCurrentSearch();
    }
  }

  async forceRefreshCurrentSearch(): Promise<void> {
    if (this.searchingChargingStations) {
      if (this.searchingChargingStationsSubscription) {
        this.searchingChargingStationsSubscription.unsubscribe();
      }
    }

    await this.searchSpots();
  }

  forceStopFetchingChargingStations(): void {
    if (this.searchingChargingStationsSubscription) {
      this.searchingChargingStationsSubscription.unsubscribe();

      this.setSearchingChargingStations(false);
    }
  }

  async searchSpots(requestedPage: number = 1): Promise<any> {
    if (
      this.currentSearchCoordinates.topLeftCorner.lng !==
        this.currentSearchCoordinates.bottomRightCorner.lng &&
      this.currentSearchCoordinates.bottomRightCorner.lat !==
        this.currentSearchCoordinates.topLeftCorner.lat
    ) {
      this.setSearchingChargingStations(true);

      if (this.searchingChargingStationsSubscription) {
        this.searchingChargingStationsSubscription.unsubscribe();
      }

      this.searchingChargingStationsSubscription = this.chargingStationService
        .searchItemsInsideCoordinates(
          this.currentSearchCoordinates.topLeftCorner.lng,
          this.currentSearchCoordinates.bottomRightCorner.lat,
          this.currentSearchCoordinates.bottomRightCorner.lng,
          this.currentSearchCoordinates.topLeftCorner.lat,
          this.zoomLevel,
          requestedPage,
          this.currentSearchFor === 'itinerary'
            ? this.itinerarySpotFilters
            : this.currentSpotFilters,
          false
        )
        .subscribe(async (data: { searchItemsInsideCoordinates: Marker[] }) => {
          if (this.searchingChargingStationsSubscription) {
            this.searchingChargingStationsSubscription.unsubscribe();
          }

          this.setMarkers(data.searchItemsInsideCoordinates);
          this.setSearchingChargingStations(false);
        });
    }
  }

  async getAllCachedChargingStation(): Promise<void> {
    if (!this.searchingChargingStations) {
      this.setSearchingChargingStations(true);

      try {
        const chargingStations: ChargingStation[] =
          await this.chargingStationService.getAllWithCache();

        if (chargingStations) {
          this.setChargingStations(chargingStations);
        }
      } catch (err) {
        //console.log(154, err);
        this.presentToast('Erreur, impossible de récupérer les stations.');
      } finally {
        this.setSearchingChargingStations(false);
      }
    }
  }

  async presentToast(msg: string): Promise<void> {
    if (!this.toastPresented) {
      this.toastPresented = true;

      const toast = await this.toastController.create({
        message: msg,
        duration: 2000
      });

      toast.present();

      await toast.onDidDismiss();
      this.toastPresented = false;
    }
  }
}
